import MegurimA from '../assets/vocabulary/MegurimA.png'
import MegurimB from '../assets/vocabulary/MegurimB.png'
import MegurimC from '../assets/vocabulary/MegurimC.png'
import MegurimD from '../assets/vocabulary/MegurimD.png'

var vocabulary = [{
    title:'מגורים א׳',
    image:MegurimA,
    aliases:['מגורים א'],
    description:'אזור בו מותרת הקמת בנייני מגורים בשטח של עד 100% משטח המגרש. בתכנית ישנות [לפני נוהל מבנה אחיד לתכנית] לרוב מתאר אזור בו מותרת הקמת בתים חד או דו משפחתיים. לעיתים מותר גם שימוש למשרדים עבור בעלי מקצועות חופשיים. במקרים מסוימים תכניות מציעות אזורי מגורים משולבים עם מוסדות ציבור, מסחר או תעסוקה'
},{
    title:'מגורים ב׳',
    image:MegurimB,
    aliases:['מגורים ב'],
    description:'אזור בו מותרת הקמת בנייני מגורים בשטח של בין 101% ל- 200% משטח המגרש. בתכנית ישנות [לפני נוהל מבנה אחיד לתכנית] לרוב מתאר אזור בו מותרת הקמת בתים משותפים בצפיפות נמוכה [3-6 דירות במגרש] ובגובה נמוך. במקרים מסוימים תכניות מציעות אזורי מגורים משולבים עם מוסדות ציבור, מסחר או תעסוקה'
},{
    title:'מגורים ג׳',
    aliases:['מגורים ג'],
    image:MegurimC,
    description:'אזור בו מותרת הקמת בנייני מגורים בשטח של בין 201% ל- 350% משטח המגרש. בתכנית ישנות [לפני נוהל מבנה אחיד לתכנית] לרוב מתאר אזור בו מותרת הקמת בתים משותפים בני 3-5 קומות. במקרים מסוימים תכניות מציעות אזורי מגורים משולבים עם מוסדות ציבור, מסחר או תעסוקה'
},{
    title:'מגורים ד׳',
    aliases:['מגורים ד'],
    image:MegurimD,
    description:'אזור בו מותרת הקמת בנייני מגורים בשטח של מעל 350% משטח המגרש. בתכנית ישנות [לפני נוהל מבנה אחיד לתכנית] לרוב מתאר אזור בו מותרת הקמת בתים משותפים בצפיפות וגובה גבוהים יחסית.  במקרים בהם התכנית מציעה אזור מגורים ד מומלץ לעיין בנספח הבינוי ובטבלה 5 בהוראות התכנית להבין את היקפי הבניה הצפויים.במקרים מסוימים תכניות מציעות אזורי מגורים משולבים עם מוסדות ציבור, מסחר או תעסוקה'
},{
    title:'תעסוקה | משרדים',
    aliases:['תעסוקה','משרדים'],
    description:'אזור בו מותרת הקמת מבנים עבור משרדים ועסקים. דוגמא לאזור תעסוקה: רמת החיל, תל אביב    '
},{
    title:'מסחר',
    aliases:['מסחר'],
    description:'אזור בו מותרת הקמת מבנים עבור מסחר קמונאי כדוגמת בתי קפה, מסעדות, בתי מרקחת, סניפי בנקים, בידור, בילוי ופנאי וכד. לעיתים תכניות מציעות חזית מסחרית: קומת קרקע עם חנויות ומעליה שימושים נוספים. דוגמא לאזור מסחרי:  קניון שבעת הכוכבים, הרצליה    דוגמא לרחוב עם חזית מסחרית: ביאליק, רמת גן '
},{
    title:'תעשיה | תעשיה קלה | מלאכה',
    aliases:['תעשיה קלה','מלאכה','תעשיה', 'תעשיה קלה ומלאכה'],
    description:'אזור בו מותרת הקמת מבנים עבור בתי מלאכה, בתי חרושת. לרוב, תכניות יציעו הנחיות לצמצום מפגעים מאזורים אלו. במקרים בהם התכנית מציעה אזור תעשיה, מומלץ לעיין בנספח הסביבתי לתכנית.    דוגמא לאזור תעשיה:  קריית אליעזר קפלן, נתניה'
},{
    title:'תעשייה עתירת ידע',
    aliases:['תעשייה עתירת ידע'],
    description:'אזור בו מותרת הקמת מבנים עבור מבני תעשיה אשר הייצור בה מתבצע על ידי תהליכים עתירי מדע או שהתוצרת שלה בעלת אופי חדשני.'
},{
    title:'מתקנים הנדסיים',
    aliases:['מתקנים הנדסיים'],
    description:'אזור בו מותרת הקמת מתקני תשתיות כגון: תחנת שאיבה, תחנת השנאה, מעבר תשתיות גז, תקשורת, דלק ואשפה. לעיתים תכניות מתירות הקמת מתקנים הנדסיים גם בשצ"פ או במוסדות ציבור.    '
},{
    title:'מבנים ומוסדות ציבור',
    aliases:['מבנים ומוסדות ציבור'],
    description:'אזור בו מותרת הקמת מבנים לצרכי הציבור. לדוגמא: גני ילדים, בתי ספר, צרכי דת, מרכזים קהילתיים, מרכזים תרבותיים, בתי חולים, תחנות משטרה, מקלטים ומחסנים לשעת חירום וכדומה. בדרך כלל השימוש בקרקע נקבע על ידי העירייה באמצעות הכנת פרוגרמה לצרכי ציבור וראייה עתידית של צרכי השכונה. לעיתים תכניות מתירה שימושים ציבוריים במשולב עם שימושי מגורים, מסחר או תעסוקה.    '
},{
    title:'שטח ציבורי פתוח | שצ"פ',
    aliases:['שטח ציבורי פתוח'],
    description:'אזור המיועד לגנים ציבוריים, חורשות ושדרות, מגרשי ספורט ומשחק, מתקני ספורט, שבילי הולכי רגל. לעיתים תכניות מאפשרות הקמת מבנים קטנים לשימוש הציבור כדוגמת קיוסקים ותאי שירותים.    שטחים ציבוריים פתוחים יכולים להיות מגוננים או מרוצפים.    דוגמא לשטח ציבורי פתוח מרוצף: כיכר דיזנגוף, תל אביב    דוגמא לשטח ציבורי פתוח "מגונן": פארק הירקון, תל אביב'
},{
    title:'ככר עירוני',
    aliases:['ככר עירוני'],
    description:'שטח פתוח לרווחת הציבור הכולל לרוב שבילים ומעברים להולכי רגל, גינון ונטיעות וריהוט רחוב    '
},{
    title:'יער | שמורת טבע | גן לאומי',
    aliases:['גן לאומי','שמורת טבע','יער'],
    description:'שטחים אשר יעודם נקבע לפי חוק הגנים הלאומיים ושמורות טבע או לפי תכנית מתאר ארצית מספר 22. לרוב יאופיינו בערכי נוף גבוהים [חורש, מיני חי מיוחדים] או ערכים היסטוריים חשובים.     '
},{
    title:'קרקע חקלאית',
    aliases:['קרקע חקלאית'],
    description:'קרקע שנועדה לעיבוד חקלאי. לרוב תכניות מתירות הקמת מבנים אשר משמשים לצורך הייצור החקלאי, עיבוד האדמה או גידול בעלי חיים.    '
},{
    title:'דרך',
    aliases:['דרך',],
    description:'אזור בו מותרת הקמת כבישים, שדרות, מעבר כלי רכב, מדרכות, ושבילים להולכי רגל, אי תנועה, גדרות, מחיצות. תכניות קובעות את רוחב זכות הדרך [בין שני מגרשים מקבילים]. באזור זה לרוב מוקמת מדרכה ומיסעה לכלי הרכב השונים. [לרבות נתיבי תחבורה ציבורית ושבילי אופניים]. המידע אודות הדרך מרוכז בסימון הנקרא רוזטה בתשריט התכנית. דרכים ראשיות, שיעודן נקבע בתכנית מתאר ארצית מספר 3 [דרכים] לרוב מגבילות את הבינוי בסמוך אליהן.    '
},{
    title:'דרך מאושרת',
    aliases:['דרך מאושרת'],
    description:'דרך אשר אושרה בתכנית קודמת. לרוב דרכים אלו קיימות בפועל.    '
},{
    title:'דרך מוצעת',
    aliases:['דרך מוצעת'],
    description:'דרך שמתוכננת בתכנית אך עדיין אינה סלולה.     '
},{
    title:'דרך משולבת',
    aliases:['דרך משולבת'],
    description:'דרך אשר הוקצתה לשימוש מעורב של כלי רכב והולכי רגל. הרחוב המשולב מתוכנן כך שהתנועה בו אינה ישירה על מנת להגביל את התנועה המוטורית ולתת עדיפות למשתמשי הדרך האחרים.     '
},{
    title:'יעוד על פי תכנית מאושרת אחרת',
    aliases:['יעוד ע״פי תכנית מאושרת אחרת', 'יעוד עפ"י תכנית מאושרת אחרת'],
    description:'אזור בו ייעודי הקרקע נותרים על פי התכנית המפורטת הקודמה שאושרה בו. ייתכן והתכנית תציע שינוי בהוראות לגבי הייעוד [לרבות שטחי בניה]    '
}]
export default vocabulary;