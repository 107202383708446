import React from 'react';
import { Link } from 'react-router-dom';

import Wrapper from '../components/Wrapper';
import './Terms.css';

export default function Terms() {
	return (
		<Wrapper>
			<div className="container terms-of-use-container">
                    <h2><b><u>מעירים - תנאי שימוש</u></b></h2>
                    <p><i>עדכון אחרון: 25.1.2021</i></p>
                    <h4><b><u>1. מבוא</u></b></h4>
                    <p>
                        1.1. המבוא למסמך זה ונספחיו מהווים חלק בלתי נפרד ממנו.
                        <br/>
                        1.2. כותרות הסעיפים הוכנסו לשם הנוחיות בלבד ואין להם, ולא יינתן להם, כל משקל בפירושו.
                        <br/>
                        1.3. ההסכם נכתב בלשון זכר מטעמי נוחות בלבד, והוא מכוון לשני המינים כאחד.
                        <br/>
                        <u>1.4. הגדרות</u>
                        <br/>
                        &nbsp;&nbsp;&nbsp;1.4.1. <b>"המפעיל"</b> – מעירים (ע"ר).
                        <br/>
                        &nbsp;&nbsp;&nbsp;1.4.2. <b>"ההסכם"</b> – מסמך תנאי שימוש זה.
                        <br/>
                        &nbsp;&nbsp;&nbsp;1.4.3. <b>"המערכת"</b> או <b>"האתר"</b> – מערכת מבוססת אינטרנט, האוספת מידע ממקורות פומביים שונים בדבר תהליכי תכנון ובנייה ו/או תכניות אחרות של רשויות מקומיות ומשרדים ממשלתיים, מעבדת אותו ומאפשרת למשתמשים בה לקרוא תכנים שונים בדבר תהליכים ותוכניות, להביע דעות ולצרף מידע אודות אותם תהליכים ותוכניות.
                        <br/>
                        &nbsp;&nbsp;&nbsp;1.4.4. <b>"משתמשים"</b> – כל מי שעושה שימוש כלשהו במערכת או גולש באתר.
                        <br/>
                        1.5. הסכם זה בא להסדיר את מערכת היחסים בין המפעיל לבין המשתמשים במערכת. האתר והמערכת לא נועדו למטרות רווח והפעילות בהם הנה פעילות המפעיל ולצורך קידום מטרותיו באמצעות פעילותו, לרבות השימוש באתר זה.
                        <br/>
                        1.6. השימוש במערכת מהווה הסכמה וקבלת תנאי הסכם זה. משתמש שאינו מסכים לתנאי השימוש (חלקם או כולם) המנוסחים בהסכם זה, אינו רשאי לעשות שימוש כלשהו במערכת או בחלק ממנה.
                        <br/>
                        1.7. תנאי הסכם זה נכונים לכל שימוש במערכת ובאתר, והשימוש במונח אחד נכון גם למונח השני, בשינויים המתחייבים. על אף האמור, על גיוס תרומות המתבצע באמצעות האתר יחולו בנוסף לתנאי שימוש אלו גם תנאי תקנון גיוס התרומות <Link to="/support-us/">הזמין בעמוד התמיכה</Link>.
                        <br/>
                        1.8. מובהר בזאת כי תנאי הסכם זה פועלים במקביל ובכפוף לתנאי השימוש של צדדים שלישיים, ככל שיהיו כאלה, והם באים להוסיף עליהם.
                    </p>
                    <h4><b><u>2. תנאי שימוש</u></b></h4>
                    <p>
                        2.1. המשתמש מתחייב להימנע מביצוע הפעולות הבאות ביחס למערכת ולשירותים המסופקים באמצעותה:
                        <br/>
                        &nbsp;&nbsp;&nbsp;2.1.1. גלישה, סריקה או שימוש במערכת באמצעות תוכנות מחשב המיועדת לאסוף מידע, או לבצע פעולות בדרך של חיקוי גולש רגיל, לרבות Bots או Crawlers וכדומה.
                        <br/>
                        &nbsp;&nbsp;&nbsp;2.1.2. מניפולציה על כתובת ה-URL של דפים פנימיים, בכדי להגיע לדפים פנימיים אליהם אין לו גישה ישירה (URL Hacking).
                        <br/>
                        &nbsp;&nbsp;&nbsp;2.1.3. העלאה או העברה של תוכן, או ביצוע פעולות, אשר יש בהן משום עבירה על חוקי מדינת ישראל או בכלל; ובכלל זאת להימנע מהעלאה, העברה או פרסום של תוכן אשר יש בו כדי להפר זכות יוצרים, סימן מסחר או סוד מסחרי של אחר, פרסום לשון הרע, דברי תועבה או פגיעה בפרטיותו של אחר.
                        <br/>
                        &nbsp;&nbsp;&nbsp;2.1.4. ביצוע פעולה בעיצוב האתר או המערכת, קוד המקור, אלמנטים או תכנים המופיעים באתר או במערכת, אשר הזכות לבצעה נתונה בלעדית, על פי חוק זכות יוצרים התשס"ח-2007, לבעל זכות היוצרים, שלא בהתאם לאמור בהסכם זה. המערכת עשויה להכיל קישוריות (לינקים) למערכות חיצוניות המאפשרות ביצוע פעולות בקוד המקור, ובמקרה כזה יחולו תנאי הרישיון והשימוש המפורטים במערכות אלו.
                        <br/>
                        &nbsp;&nbsp;&nbsp;2.1.5. התנהגות אלימה, בוטה, פלילית או כזו הפוגעת בכל צורה שהיא, כלפי המפעיל, כלפי משתמשים אחרים במערכת או כלפי כל אדם אחר באמצעות המערכת.
                        <br/>
                        2.2. המפעיל שומר לעצמו את הזכות:
                        <br/>
                        &nbsp;&nbsp;&nbsp;2.2.1. להפסיק או למנוע את השירות, או חלקים ממנו, ממשתמש אשר הפר סעיף מסעיפי הסכם זה, או בכלל; הכל על פי שיקול דעתו הבלעדי של המפעיל, ומבלי שיהיה חייב להודיע על כך מראש למשתמש. לא תישמע כל טענה של המשתמש בעניין זה.
                        <br/>
                        &nbsp;&nbsp;&nbsp;2.2.2. להסיר כל תוכן או לבטל כל פעולה אשר בוצעה על-ידי המשתמש ואשר יש בה משום הפרת סעיף מסעיפי הסכם זה, או בכלל.
                        <br/>
                        &nbsp;&nbsp;&nbsp;2.2.3. המפעיל יהיה רשאי לחסום גישה למערכת – מכתובות או מאתרים שעלולים לגרום נזק למפעיל או לשירותיו –לפי שיקול דעתו הבלעדי של המפעיל וללא צורך בהודעה מוקדמת.
                    </p>
                    <h4><b><u>3. חשבון משתמש</u></b></h4>
                    <p>
                        3.1. הגישה לשירותים מסוימים, דוגמת  התראות על תכניות מסוימות (לרבות התראות המבוססות על מיקום גאוגרפי של המשתמש), האפשרות לכתוב תגובות, לשמור תוכניות מועדפות וכן מאפיינים עתידיים שיתווספו למערכת, תתאפשר רק למשתמש אשר נרשם למערכת, ומסר למפעיל פרטים מסוימים, אשר ייקבעו על-ידי המפעיל (להלן: <b>"חשבון משתמש"</b> או <b>"החשבון"</b>).
                        <br/>
                        3.2. ההוראות הבאות יחולו על יצירת חשבון משתמש:
                        <br/>
                        &nbsp;&nbsp;&nbsp;3.2.1. אסור ליצור חשבון משתמש המכיל נתונים אשר אינם פרטיו האמתיים והמלאים של פותח החשבון.
                        <br/>
                        &nbsp;&nbsp;&nbsp;3.2.2. אסור ליצור חשבון עבור אדם אשר אינו נוכח מול צג המחשב או הטלפון בעת ההרשמה, או אשר אינו מאשר את תנאי הסכם זה.
                        <br/>
                        <b><u>לעניין הוראות אלו יודגש כי התחזות היא עבירה פלילית.</u></b>
                        <br/>
                        3.3. מובהר בזאת כי על אף שהמפעיל נוקט באמצעים לאבטחת תוכן חשבון המשתמש, הוא אינו יכול להבטיח בצורה מוחלטת את אי חשיפתו כתוצאה מחדירה לא מורשית, ולמשתמש לא תהיה כל טענה כלפי המפעיל בגין נזקים, אשר ייגרמו כתוצאה מחדירה לא מורשית לחשבון המשתמש.
                        <br/>
                        3.4. בעת יצירת חשבון משתמש, ייתכן והמפעיל תישלח למשתמש הודעת דואר אלקטרוני ובה קוד אימות שנועד לצרכי זיהוי והרשמה. המשתמש מאשר למפעיל משלוח מסרונים כאמור ומתחייב לשמור על הקוד בסודיות ולצורך הרשמתו האישית בלבד. אין להעביר את הקוד לכל גורם אחר.
                        <br/>
                        3.5. בטופס ההרשמה, מילוי כתובת בשדה "כתובת" יפתח למשתמש התראה אוטומטית סביב כתובת זו ברדיוס ברירת מחדל של 4 ק"מ. המשתמש מאשר את פתיחת התראה אוטומטית זו. המשתמש יכול להשאיר את השדה ריק, במקרה זה לא תיפתח התראה. שדה זה אינו חובה וניתן לבחור שלא למלא אותו ו/או להסיר את מילוי שדה זה בעתיד.
                        <br/>
                        3.6. המשתמש מאשר למפעיל לשלוח לתיבת הדוא"ל שלו, לפי הפרטים שהוזנו בחשבון המשתמש, עדכונים ואישורים ביחס לפעולות המשתמש באתר, וכל הודעה אחרת, בפרט, מאשר המשתמש למפעיל לשלוח לו הודעות אישיות ו/או דוא"ל לצורך אימות תכנים המועלים על ידו.
                    </p>
                    <h4><b><u>4. משלוח הודעות פרסומת</u></b></h4>
                    <p>
                        4.1. החברה רשאית והמשתמש מצהיר כי הוא מאשר למפעיל, לשלוח לו הודעות פרסומת, מעת לעת, באמצעות מסרונים, הודעות דואר אלקטרוני ובכל דרך אפשרית אחרת. מסירת כתובת הדואר האלקטרוני או מספר הטלפון של המשתמש – כמוה כהסכמה מפורשת לקבלת הודעות כאמור.
                        <br/>
                        4.2. למשתמש שמורה הזכות להודיע בכל עת למפעיל, כי ברצונו להפסיק לקבל הודעות כאמור – אם באמצעות שליחת הודעת דואר אלקטרוני מתאים למפעיל ואם באמצעות דרכי ההסרה המופיעות בכל הודעה.
                    </p>
                    <h4><b><u>5. תשלום לספקי שירותים</u></b></h4>
                    <p>
                        5.1. ככל והמשתמש יעשה שימוש בשירות כלשהו מהשירותים המסופקים על ידי צדדים שלישיים באמצעות המערכת, ייתכן והמשתמש יידרש לתשלום בגין אותו השירות. במקרה כזה, המשתמש לבדו אחראי לתשלום מלוא הסכום הנדרש, כפי שיהיה מעת לעת, ישירות לספק השירות (המהווה צד שלישי), כפי שיידרש.
                        <br/>
                        5.2. אחריותו של המשתמש לשלם לספק השירות כאמור, היא עצמאית ומנותקת מאחריותו של כל משתמש אחר, ככל שקיימת, גם במקרה שקיימים משתמשים אחרים להם מסופק השירות באותה עת ובמשותף עם המשתמש.
                        <br/>
                        5.3. המפעיל לא יהיה אחראי בשום מקרה, לתשלום במקום המשתמש, לשיפוי המשתמש או לכל נזק, הוצאה או הפסד שייגרמו לו כתוצאה מחובתו לשלם לספק השירות כאמור לעיל.
                    </p>
                    <h4><b><u>6. הגבלת אחריות</u></b></h4>
                    <p>
                        6.1. מובהר בזאת כי המערכת והשירותים המסופקים באמצעותה, יסופקו על-ידי המפעיל כמות שהם (As-Is). המפעיל לא ישא בשום אחריות שהיא בגין נזק כלשהו שייגרם למשתמש (או למי מטעמו), בגין שימוש במערכת או בשירותים המסופקים באמצעותה; או בגין שימוש במוצרים או שירותים של צדדים שלישיים, בין אם שירותים אלה מסופקים באופן דיגיטלי ובין אם בכל אופן אחר.
                        <br/>
                        6.2. מבלי לגרוע מהאמור יודגש, כי חלקים מהמידע המובא באמצעות המערכת הנו מידע המופק על ידי צדדים שלישיים, לרבות מוסדות, ארגונים ומשתמשי המערכת, ולפיכך למפעיל אין כל אחריות בגין נכונות חלקים אלו של המידע, דיוקם או התאמתם לצרכי המשתמשים.
                        <br/>
                        6.3. מובהר בזאת כי המפעיל לא מתחייב שהמערכת תפעל 100% מהזמן; כי מעת לעת המפעיל עשוי לעדכן ולשדרג את המערכת – דבר שעלול לגרום להפסקה זמנית במתן השירות; וכי אין למפעיל כל שליטה על זמינות המוצרים או השירותים המסופקים על-ידי צדדים שלישיים.
                        <br/>
                        6.4. באחריות המשתמש לבחון, טרם השימוש במערכת, כי המערכת אכן מתאימה לצרכיו, ולמשתמש לא תהיה כל טענה בשל אי התאמת המערכת לצרכיו.
                        <br/>
                        6.5. באחריות המשתמש לוודא טרם השימוש המערכת, את תקינות החיבור שלו לרשת האינטרנט – המפעיל לא ישא בכל אחריות בשל נזק אשר ייתכן וייגרם למשתמש עקב בעיות בחיבורו לרשת האינטרנט.
                        <br/>
                        6.6. המפעיל לא יהיה אחראי בשום מקרה, לתכנים אשר יועלו, יועברו או יפורסמו באמצעות המערכת. האחריות בגין פעולות אלה תחול על המשתמש בלבד, ולא תישמע כל טענה נגד המפעיל בעניין זה.
                    </p>
                    <h4><b><u>7. היעדר אחריות לפעילות משתמשים ולתוכן המועלה על ידי משתמשים</u></b></h4>
                    <p>
                        7.1. במסגרת שירותי המערכת, ובכפוף לאמור בתנאי שימוש אלה, באפשרות ציבור המשתמשים להעלות ולהציג תכנים שונים, פרי ידם, באתר (להלן: <b>"תוכן משתמשים"</b>) – בפרט תגובות וחוות דעת אודות מידע תכנוני, קישורים ותמונות לתכניות, הודעות, או משובים אודות תוכן של משתמשים אחרים, כמו גם מידע אישי של המשתמש, שיוכנס בדף הפרופיל שלו ויוצג ליד תוכן שיועלה על ידו.
                        <br/>
                        7.2. מובהר כי מנגנון העלאת תוכן המשתמשים הוא, במלואו או בחלקו, הליך המתנהל ללא מעורבות אדם, והמפעיל אינו יכול לאמת את זהות מעלה התוכן או לבחון את מהות התוכן המועלה טרם הצגתו, ולא יהיה אחראי לתוכן המשתמשים המוצג במערכת, לאמינותו, רמת דיוקו או התאמתו לצרכי המשתמשים האחרים.
                        <br/>
                        7.3. במסגרת שירותי האתר חלק מתכני האתר, לרבות תוכן גולשים, דפי ספקים או מוצרים, עשויים להיות ניתנים לדירוג ולמתן חוות דעת מילולית על ידי ציבור המשתמשים.
                        <br/>
                        7.4. מובהר כי הדירוג ו/או חוות הדעת אינם משקפים את עמדתו של המפעיל או את דעתו אלא של המשתמשים בלבד, ולמפעיל לא תהיה כל אחריות כלפי אף אדם בקשר עם הדירוג ו/או חוות הדעת.
                        <br/>
                        7.5. מבלי לפגוע באמור בסעיף ‎7.4, באם למשתמש יסוד להניח כי אדם אחד עומד מאחורי מספר גדול של דירוגים או חוות דעת, הוא מוזמן להפנות את תשומת לבו של המפעיל, אשר שומר על זכותו, על פי שיקול דעתו הבלעדי, לבטל פעולות דירוג שנעשו תוך הפרת תנאי שימוש אלו.
                    </p>
                    <h4><b><u>8. נוהל הודעה והסרה</u></b></h4>
                    <p>
                        8.1. האתר הינו אתר המכיל תוכן, אשר נכתב בחלקו על ידי המשתמשים, בחלקו על ידי צדדים שלישיים ובחלקו על ידי המפעיל ו/או הועלה על ידי אלגוריתם אוטומטי לאיתור מידע ברחבי הרשת.
                        <br/>
                        8.2. העלאת תכנים לאתר על ידי המשתמש כפופה להצהרת המשתמש אשר מעלה תוכן לאתר כי התוכן אינו מפר זכויות יוצרים, אינו מהווה לשון הרע, אינו פוגעני וכיו"ב.
                        <br/>
                        8.3. אם בכל זאת הנך סבור כי האתר מכיל תוכן המפר זכויות יוצרים, מהווה לשון הרע, פוגעני וכיו"ב, המפעיל מתחייב לפעול להסרת התוכן הרלוונטי לאחר מסירת הודעה כאמור, בהתאם ל"נוהל הודעה והסרה", אשר נקבע בפסיקת בית המשפט בעניינים אלו.
                        <br/>
                        8.4. בהתאם, יובהר כי האתר אינו אחראי להפרת זכויות קניין רוחני, לשון הרע, פגיעה בפרטיות, הטעיה, תוכן פוגעני וכיו"ב, בנוגע לכל תוכן שהועלה על ידי משתמשים ו/או תוכן פומבי המצוי בנחלת הכלל שאותר על ידי המערכת ו/או על ידי המפעיל באתרים פומביים, שכן כאמור וכמפורט לעיל, האתר מהווה "צינור" להעברת המידע בלבד, והוא מוצג באתר בצורה נוחה, קלה לשימוש ולהבנה.
                        <br/>
                        8.5. יובהר כי המפעיל אינו מחוייב להסיר תוכן ו/או מידע המופיע ברחבי הרשת וכי מעלה התוכן ו/או כותב התוכן יוכל לעדכן את נכונות הפרטים בכל עת מול שירות לקוחות האתר בדוא"ל <a href="mailto:info@meirim.org">info@meirim.org</a>.
                    </p>
                    <h4><b><u>9. העלאת תוכן על ידי משתמש</u></b></h4>
                    <p>
                        9.1. מבלי לגרוע מהאמור לעיל, המשתמש מצהיר ומתחייב, כי אין בהעלאת התוכן למערכת, או בשימוש בו על ידי המפעיל או המשתמשים, בהתאם לתנאי שימוש אלו, בכדי להפר את חוקי מדינת ישראל או בכלל, ובכלל זה להפר זכות יוצרים או סימן מסחר, לגלות סוד מסחרי, או להוות פגיעה בפרטיות, תועבה או פרסום לשון הרע.
                        <br/>
                        9.2. המשתמש מתחייב שלא להעלות תוכן אשר עלול לפגוע בתקנת הציבור, תוכן אשר יש בו כדי לפגוע בזכויותיו או רגשותיו של אדם, גוף או ציבור, או תוכן פרסומי או מטעה.
                        <br/>
                        9.3. המפעיל אינו מתחייב להציג את התוכן המועלה ושומר על זכותו לסרב להעלות תוכן או למחקו, בהתאם לאמור בתנאי שימוש אלו.
                        <br/>
                        9.4. המשתמש שומר בידיו את זכות היוצרים על כל תוכן אשר העלה לאתר, ואשר הוא בעל זכות היוצרים בו, אך מעניק למפעיל רישיון, לא בלעדי ושאינו מוגבל בזמן, להעמדת התוכן לרשות הציבור ומשתמשי המערכת, ולעשות בו שימוש לפי שיקול דעתו של המפעיל, לרבות שימוש שיווקי.
                        <br/>
                        9.5. מובהר כי המשתמש, כבעל זכות היוצרים בתוכן שהועלה על ידו, אחראי לאכיפת הרשאות השימוש ביחס לתוכן זה.
                        <br/>
                        9.6. המשתמש מאשר למפעיל לבצע עריכה מסוימת של התוכן המועלה למערכת על מנת להתאימו למבנה האתר ומתחייב לעמוד בהנחיות הטכניות להעלאת התוכן למערכת, כפי שיופיעו באתר.
                    </p>
                    <h4><b><u>10. היעדר אחריות לפרסומות ולקישורים יוצאים</u></b></h4>
                    <p>
                        10.1. ייתכן ופרסומות למוצרי או שירותי צדדים שלישיים יהיו מוצגות במערכת בחסות שירות חיצוני, המתאים את הפרסומות לתוכן הדף. מובהר כי למפעיל אין כל שליטה על בחירת הפרסומות המוצגות, סדר הופעתן או אמיתות תוכנן.
                        <br/>
                        10.2. חלק מהקישורים (Hyper-Links) המופיעים במערכת מפנים לאתרים בניהול צדדים שלישיים או בבעלותם. מובהר כי הקישור לאתרים אלו נעשה אך ורק לנוחות המשתמש וכי המפעיל לא בחן אתרים אלה, ואינו תומך בהם או אחראי להם, לתוכנם או לאבטחתם, וכי גלישה, שימוש או מסירת מידע לאתרים אלו היא באחריותו המלאה של המשתמש.
                        <br/>
                        10.3. יודגש שלא קיימת אבחנה בין סימון קישורים פנימיים לקישורים יוצאים וכי באחריות המשתמש לוודא את כתובת ה-URL אליו מפנה קישור טרם השימוש בו.
                    </p>
                    <h4><b><u>11. הודעה על תוכן מפר</u></b></h4>
                    <p>
                        11.1. המפעיל אמון על שמירת חוקי מדינת ישראל – אם נתקלתם בתוכן המפר את חוקי מדינת ישראל או בכלל, נודה לכם את תפנו את תשומת לבנו באמצעות משלוח הודעה, המפרטת את התוכן המפר ומהות ההפרה, לכתובת הדואר האלקטרוני <a href="mailto:info@meirim.org">info@meirim.org</a>.
                    </p>
                    <h4><b><u>12. קניין רוחני</u></b></h4>
                    <p>
                        12.1. המפעיל הוא הבעלים של מלוא הזכויות, לרבות זכויות הקניין הרוחני, במערכת ובכל השירותים שיסופקו על-ידי המפעיל, ובכל הכלים שישמשו את המפעיל במהלך מתן השירותים. כל זאת, לרבות כל שינוי, שיפור, תוספת או יצירה נגזרת של האמור לעיל, ולמעט מוצרים ושירותים של צדדים שלישיים.
                        <br/>
                        12.2. על אף האמור יודגש, כי
                        <br/>
                        12.3. המשתמש לא יהיה זכאי לעשות כל שימוש במערכת או בכל אחד מחלקיה, באופן הנוגד את הוראות הסכם זה, ואשר יש בו לפגוע בזכויות המפעיל או בזכויות של צד שלישי כלשהו – מבלי שקיבל את הסכמת המפעיל בכתב ומראש.
                        <br/>
                        12.4. הוראות סעיף 12 זה יחולו אף לאחר הפסקת מתן השירות וסיומו של הסכם זה, מכל סיבה שהיא.
                    </p>
                    <h4><b><u>13. שינוי תנאים</u></b></h4>
                    <p>
                        13.1. החברה רשאית לבצע שינויים בהסכם זה או בנספחיו בכל עת, ומבלי שתצטרך לתת על כך הודעה מראש.
                        <br/>
                        13.2. באחריות המשתמש להיכנס לדף זה בכדי לברר האם בוצעו שינויים בהסכם זה.
                    </p>
                    <h4><b><u>14. שיפוי</u></b></h4>
                    <p>
                        14.1. המשתמש מתחייב כי ישפה ויפצה את המפעיל, בגין כל הפסד, נזק או חסר (לרבות הוצאות ושכ"ט עו"ד) שייגרמו לחברה, בקשר עם כל דרישה או תביעה שתופנה אליה, כתוצאה משימושו של המשתמש באפליקציה, מכל גורם שהוא; כל זאת, תוך 30 יום ממועד קבלת דרישת החברה לראשונה בכתב.
                        <br/>
                        14.2. האמור בסעיף 14 זה אינו גורע מכל זכות או סעד אחרים, העומדים לחברה לפי הסכם זה או לפי כל דין.
                    </p>
                    <h4><b><u>15. ברירת דין וסמכות שיפוט</u></b></h4>
                    <p>
                        סמכות השיפוט הייחודית והבלעדית בכל הקשור לנושאים ולעניינים הנובעים או הקשורים להסכם, תהא אך ורק לבית המשפט המוסמך שמקום מושבו בתל-אביב. הדין שיחול על הסכם זה יהיה הדין הישראלי.
                    </p>
			</div>
		</Wrapper>
	);
}
