import React from 'react';
import * as SC from './style';

const TermsOfPayment = ({ ...props }) => {
	
	return (
        <>
                <SC.CentredWrapper>
                    <SC.CentredTitle>תקנון תרומות</SC.CentredTitle>
                    <SC.TermsOfPaymentText>
                        תקנון תרומות זה הנו נספח לתנאי השימוש של האתר של מעירים (ע"ר) 580714285 ומאפשר איסוף תרומות עבור מעירים.
                        <br/>
                        <br/>
                        <u>1. הגדרות</u>
                        <br/>
                        &nbsp;&nbsp;&nbsp;1.1. מעירים – עמותה מעירים (ע"ר) 580714285.
                        <br/>
                        &nbsp;&nbsp;&nbsp;1.2. אתר – אתר מעירים https://meirim.org.
                        <br/>
                        &nbsp;&nbsp;&nbsp;1.3. משתמש באתר – כל מי שבוחר לגלוש באתר.
                        <br/>
                        &nbsp;&nbsp;&nbsp;1.4. תורם – כל מי שבוחר לתרום למעירים דרך האתר.
                        <br/>
                        &nbsp;&nbsp;&nbsp;1.5. חברת הסליקה – החברה איתה תבחר מעירים לעבוד.
                        <br/>
                        2. האתר מאפשר איסוף תרומות עבור מעירים.
                        <br/>
                        3. מעירים מאפשרת למשתמשים באתר לתרום כסף לקידום מטרות מעירים ולמען פעילות מעירים.
                        <br/>
                        4. האתר מאפשר תרומה למעירים על-ידי שימוש בכרטיס אשראי בדרך בטוחה קלה ומהירה.
                        <br/>
                        5. חברת הסליקה של האתר עומדת בתקן PCI.
                        <br/>
                        6. מעירים תספק לכל תורם קבלה על אישור תרומה בהתאם לדין.
                        <br/>
                        7. בדיקת כרטיס האשראי תבוצע על-ידי חברת הסליקה.
                        <br/>
                        8. במידה והתרומה באמצעות כרטיס האשראי לא אושרה על-ידי חברת הסליקה, תעדכן מעירים את מבצע הפעולה על מנת שיוכל לתרום בדרך אחרת ישירות מול מעירים.
                        <br/>
                        9. מעירים אינה אוספת מידע על אמצעי התשלום, כל תהליך התשלום יבוצע מול חברת הסליקה ובהתאם לתנאי השירות של חברת הסליקה.
                        <br/>
                        10. התרומה אינה למטרת רווח ותשמש אך ורק בהתאם לסעיף 17 להלן.
                        <br/>
                        11. טעות בהקלדת נתונים על ידי תורם תהיה באחריותו המלאה והבלעדית, והתורם יהיה זה שיישא בכל תוצאה העלולה לקרות מטעות בהקלדת הנתונים.
                        <br/>
                        12. רישומי התרומה כפי שיופיעו אצל חברת הסליקה ו/או מעירים יהוו ראיה לכאורה לכך כי הנתונים המופיעים בהם הם הנתונים אשר הוקלדו על ידי התורם.
                        <br/>
                        13. תורם יהיה רשאי לפנות לעמותה בבקשה לשינוי פרטי החיוב וביטול עסקה וזאת דרך הדוא"ל <a href="mailto:info@meirim.org">info@meirim.org</a> או דרך הטלפון: 052-5969964.
                        <br/>
                        14. עסקה ניתן לבטל בהתאם להוראות חוק הגנת הצרכן, התשמ"א 1981- והתקנות שהותקנו על פיו וזאת תוך 14 ימים מיום שבוצעה התרומה דרך האתר.
                        <br/>
                        15. במידה ופנה תורם למעירים בהתאם לסעיפים 13-14 לעיל, מעירים תעדכן את האמור בפניית התורם בהתאם למדיניות הנהוגה באותה עת בחברת הסליקה.
                        <br/>
                        16. במידה וחברת הסליקה תחייב את מעירים בעמלה בגין העדכון, יחויב התורם בעמלה כאמור.
                        <br/>
                        17. <b>השימוש בתרומה – מעירים תוכל לעשות שימוש בכל תרומה לצורך הפעילות השותפת שלה ולמען קידום מטרות מעירים, לרבות אך לא רק לצורך הוצאות הנהלה וכלליות, והכל לפי שיקול הדעת של מעירים.</b>
                        <br/>
                        18. <b>תרומות מעל לסכום הקבוע בחוק מחויבות בפרסום בדיווח השנתי של מעירים.</b>
                        <br/>
                        19. מעירים הוקמה בתאריך 23.12.2020 ועל כן עדיין אינה מחזיקה באישור ניהול תקין.
                        <br/>
                        20. מעירים מחזיקה אישור מרשות המיסים, אך בשלב זה אין לעמותה אישור על פי סעיף 46א' לפקודת מס הכנסה.
                        <br/>
                        21. <b>ביצוע תרומה באמצעות האתר מהווה הסכמה לתקנון זה.</b>
                        <br/>
                        22. <b>התורם מצהיר כי הוא כשיר לביצוע פעולת התרומה.</b>
                        <br/>
                        23. במידה והנך מעוניין לתרום אך אינך מסכים לתנאיו של תקנון זה, אנא אל תעשה שימוש באתר לצורך תרומה ופנה ישירות אל מעירים דרך הפרטים המצוינים בסעיף 13 לעיל.
                        <br/>
                        24. מעירים תהיה זכאית לשנות תקנון זה מפעם לפעם ללא צורך בהודעה מוקדמת למשתמשי האתר או לתורמים.
                        <br/>
                        25. תקנון זה, הנו תוספת לתנאי השירות של האתר ולמדיניות הפרטיות של האתר וכל הכללים הקבועים בהם יחולו גם על תקנון זה.
                    </SC.TermsOfPaymentText>
                </SC.CentredWrapper>
        </>
	)
};

export default TermsOfPayment;
