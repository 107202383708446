import React from 'react';

import Wrapper from '../components/Wrapper';

import "./PrivacyPolicy.css";

export default function PrivacyPolicy() {
	return (
		<Wrapper>
            <div className="container privacy-policy-container">
                    <h2><b><u>מעירים - מדיניות פרטיות</u></b></h2>
                    <p><i>עדכון אחרון: 24.2.2021</i></p>
                    <h4><b><u>אודות המערכת</u></b></h4>
                    <p>
                        המערכת היא פלטפורמה מבוססת אינטרנט, האוספת מידע ממקורות פומביים שונים בדבר תהליכי תכנון ובנייה ו/או תכניות אחרות של רשויות מקומיות ומשרדים ממשלתיים, מעבדת אותו ומאפשרת למשתמשים בה לקרוא תכנים שונים בדבר תהליכים ותוכניות, להביע דעות ולצרף מידע אודות אותם תהליכים ותוכניות.
                    </p>
                    <h4><b><u>מי אנחנו?</u></b></h4>
                    <p>
                        מעירים (ע.ר.) היא המפתחת והמפעילה של הפלטפורמה, לרבות האתר ושאר השירותים המסופקים על-ידה (כולם ביחד – <b>"הפלטפורמה"</b>).
                    </p>
                    <p>
                        האתר והפלטפורמה לא נועדו למטרות רווח והפעילות בהם הנה פעילות עמותת מעירים ולצורך קידום מטרותיה באמצעות הפעילות שלה, לרבות השימוש באתר ובפלטפורמה.
                    </p>
                    <h4><b><u>מה עניינה של מדיניות הפרטיות הזו?</u></b></h4>
                    <p>
                        יצרנו את מדיניות הפרטיות הזו כי אנחנו מעריכים מאוד את המידע האישי שלך; אנא קרא אותה בזהירות, שכן היא כוללת מידע חשוב בנוגע לכך.
                    </p>
                    <p>
                        אנו פועלים כמיטב יכולתו להגנת פרטיותם של המשתמשים, בהתאם לחוקי ותקנות הגנת הפרטיות.
                    </p>
                    <p>
                        אתה מוזמן לפנות אלינו בכל שאלה בנוגע למדיניות הפרטיות, לכתובת הדואר האלקטרוני: <a href="mailto:info@meirim.org">info@meirim.org</a>.
                    </p>
                    <p>
                        מדיניות פרטיות זו נכתבה בלשון זכר מטעמי נוחות, אך הנה פונה לנקבה ולזכר כאחד.
                    </p>
                    <h4><b><u>הסכמה</u></b></h4>
                    <p>
                        <b>אני מצהיר כי גילי הוא מעל 18, וכי כל שימוש שאעשה (או שארשה לאחרים לעשות בשמי) בפלטפורמה, מהווה את הסכמתי לתנאי מדיניות הפרטיות הזו, לרבות – איסוף, שימוש, גילוי, העברה או אחסון – של כל מידע אישי או פרטי המתקבל על ידנו בכפוף לתנאי מדיניות פרטיות זו.</b>
                    </p>
                    <h4><b><u>איזה מידע אנחנו אוספים?</u></b></h4>
                    <p>
                        <u>1. מידע אישי:</u>
                        <br/>
                        &nbsp;&nbsp;&nbsp;1.1. שם משתמש (יכלול שם פרטי ושם משפחה)
                        <br/>
                        &nbsp;&nbsp;&nbsp;1.2. כתובת דואר אלקטרוני
                        <br/>
                        &nbsp;&nbsp;&nbsp;1.3. סיסמא שבוחר המשתמש
                        <br/>
                        &nbsp;&nbsp;&nbsp;1.4. סוג פרופיל (מתוך רשימה סגורה המופיעה בפלטפורמה)
                        <br/>
                        &nbsp;&nbsp;&nbsp;1.5. כתובת מגורים (שדה רשות, לשם יצירת התראות.)
                        <br/>
                        &nbsp;&nbsp;&nbsp;1.6. תמונה ומידע חופשי (שדה רשות - במידה והמשתמש מעוניין בהצגתם ליד תגובותיו)
                        <br/>
                        <u>2. מידע לא-אישי:</u>
                        <br/>
                        &nbsp;&nbsp;&nbsp;2.1. אופן השימוש בפלטפורמה
                        <br/>
                        &nbsp;&nbsp;&nbsp;2.2. עוגיות
                        <br/>
                        &nbsp;&nbsp;&nbsp;2.3. סוג המערכת (חומרה ומערכת הפעלה) באמצעותה נעשה שימוש בפלטפורמה
                    </p>
                    <h4><b><u>מה נעשה עם המידע שאנחנו אוספים?</u></b></h4>
                    <p>
                        <u>1. מידע אישי</u>
                        <br/>
                        מידע אישי ייאסף אך ורק ממשתמשים רשומים (כהגדרתם בתנאי השימוש של הפלטפורמה). כל מידע אישי שנאסוף, מטופל בהתאם להוראות מדיניות הפרטיות, ויכול לשמש ל:
                        <br/>
                        &nbsp;&nbsp;&nbsp;1.1. <u>גישה ושימוש</u> – כל מידע אישי שתמסור לנו יכול לשמש אותנו בכדי לספק לך גישה לפלטפורמה או שימושיות בה, ולניטור השימוש שלך באותם השירותים.
                        <br/>
                        &nbsp;&nbsp;&nbsp;1.2. <u>מטרות שלנו</u> – כל מידע אישי שתמסור לנו, יכול לשמש אותנו בכדי לשפר את השימושיות של השירותים שאנחנו מספקים, להבנה טובה יותר של המשתמשים, לזיהוי ומניעה של שימושים לא נאותים ולהגנה מפניהם, לאכיפת תנאי השימוש שלנו, ולניהול הכללי והשוטף של הפלטפורמה.
                        <br/>
                        &nbsp;&nbsp;&nbsp;1.3. <u>מטרות ספציפיות</u>  – כל מידע אישי שתמסור לנו, למטרה מסוימת, יכול לשמש אותנו בקשר למימוש אותה מטרה. חלק מהמידע האישי, דוגמת כתובת דוא"ל ותמונה, יפורסם בפלטפורמה בכפוף לאישור ספציפי של משתמש שיהיה מעוניין בהצגתו. כתובת ו/או מיקום גאוגרפי יכולים לשמש לחלק מהתכונות (פיצ'רים) של הפלטפורמה, דוגמת הצגת תוכניות ברדיוס גאוגרפי קרוב ו/או משלוח התראות במידה ומוצגות בפלטפורמה תוכניות חדשות מרדיוס גאוגרפי קרוב ו/או שהוגדר על ידי המשתמש.
                        <br/>
                        &nbsp;&nbsp;&nbsp;<b>מבלי לגרוע מכלליות האמור, יצוין כי כחלק אינטגרלי מהשימושיות בפלטפורמה, אנו אוספים נתוני מיקום הקשורים בנקודות הציון הגאוגרפיות שלגביהן הנך מעלה תוכן, וכן עשויים לאחסן ולפרסם תמונות ותוכן אחר שתעלה, על פי בקשתך.</b>
                        <br/>
                        &nbsp;&nbsp;&nbsp;1.4. <u>שיווק</u> – כל מידע אישי שתמסור לנו, יוכל לשמש אותנו לצורך בעתיד למטרות שיווקיות או פרסומיות, לרבות (אך לא רק( פניה אליך בנוגע לשירותים חדשים שאנו מאמינים שיכולים לעניין אותך וכדי לפתח חומרי קידום ושיווק ולספקם לך.
                        <br/>
                        &nbsp;&nbsp;&nbsp;1.5. <u>סטטיסטיקה</u> – כל מידע אישי שתמסור לנו להכנת דוחות המכילים מידע וניתוחים סטטיסטיים.
                        <br/>
                        <u>2. מידע לא-אישי</u>
                        <br/>
                        מידע לא-אישי עשוי להיאסף הן ממשתמשים רשומים והן ממשתמשים לא רשומים. היות ומידע לא-אישי לא יכול לזהות אותך באופן ישיר, אנחנו שומרים את הזכות לעשות כל שימוש אפשרי על פי חוק, במידע לא-אישי.
                    </p>
                    <h4><b><u>איזה מידע נחלוק עם צדדים שלישיים?</u></b></h4>
                    <p>
                        <u>1. מידע אישי</u>
                        <br/>
                        אנחנו לא מתכוונים למכור את המידע האישי שלך לאף גורם אחר, ואין בכוונתנו לחלוק מידע אישי שלך, מלבד פרסום תוכן באפליקציה על פי בקשתך במסגרת השימוש.
                        <br/>
                        עם זאת, מטבע הדברים, ישנם מצבים בהם ניאלץ למסור, לשתף או להעביר מידע אישי – מבלי לתת לך הודעה מוקדמת – בתנאים הבאים:
                        <br/>
                        &nbsp;&nbsp;&nbsp;1.1. <u>מחויבות על-פי חוק</u> – בכל מקרה בו נאמין, בתום לב, שגילוי מידע יהיה הכרחי על-פי חוק, לרבות אך לא רק, במקרה של צווים של גופים ממשלתיים, בתי משפט או רשויות רשמיות אחרות, בכל סמכות שיפוט שהיא.
                        <br/>
                        &nbsp;&nbsp;&nbsp;1.2. <u>מניעת עוולה או עבירה</u> – בכל בו נאמין, בתום לב, שגילוי מידע יהיה הכרחי לשם מניעת עבירה פלילית, נזק או הונאה.
                        <br/>
                        &nbsp;&nbsp;&nbsp;1.3. <u>מטרות עסקיות</u> – ככל שנפתח את פעילותנו, ייתכן שנרכוש או נמכור עסקים ונכסים. במקרה של מכירה, ארגון מחדש, פירוק או כל אירוע דומה, מידע אישי יכול להיות כלול במסגרת הנכסים המועברים. אתה מודע ומסכים כי כל מי שירכוש  את הפלטפורמה או ייכנס בנעלי מפעילת הפלטפורמה, ימשיך להחזיק באותן זכויות בקשר למידע אישי ופרטיות, בהתאם לתנאי מדיניות הפרטיות.
                        <br/>
                        &nbsp;&nbsp;&nbsp;1.4. <u>סטטיסטיקה</u> – אנו נהיה רשאים להשתמש במידע אישי שתמסור לנו, לשם הכנת דוחות המכילים מידע וניתוחים סטטיסטיים, אותם נוכל למסור לצורך קידום הפלטפורמה. דוחות כאמור יוכנו על ידינו, או באמצעות כלים המסופקים על ידי צדדים שלישיים, לרבות Hotjar, אשר מקבל את המידע המפורט בלינק: <a href="https://help.hotjar.com/hc/en-us/articles/1500000187901-Categories-of-Personal-Data#categories-of-personal-data-processed-on-behalf-of-hotjar-customers" target="_blank" rel="noopener noreferrer">https://help.hotjar.com/hc/en-us/articles/1500000187901-Categories-of-Personal-Data#categories-of-personal-data-processed-on-behalf-of-hotjar-customers</a>.
                        <br/>
                        <u>2. מידע לא-אישי</u>
                        <br/>
                        היות ומידע לא-אישי לא יכול לזהות אותך באופן ישיר, אנחנו שומרים את הזכות לעשות כל שימוש אפשרי על פי חוק, במידע לא-אישי.
                    </p>
                    <h4><b><u>קישורים לאתרים אחרים</u></b></h4>
                    <p>
                        הפלטפורמה עשויה להכיל קישורים לאתרי אינטרנט ו/או שירותים של צדדים שלישיים. אנחנו לא נהיה אחראים על מדיניות הפרטיות באותם אתרים, וממליצים לך לבדוק בעצמך את אותה מדיניות פרטיות, בכל מקרה ובכל אתר או שירות.
                    </p>
                    <h4><b><u>אחסון ואבטחת מידע</u></b></h4>
                    <p>
                        אנחנו מחויבים מאוד להגנה על המידע האישי והפרטיות שלך, ועושים כל פעולה טכנית סבירה, שמקובלת בתעשייה, לשמירה על בטחון המידע האישי והפרטיות שלך, ולהגנה עליהם מפני אובדן, שינוי או שימוש לא ראוי. עם זאת, אם תבחין בפרצה כלשהי באבטחת המידע, אנא הודע לנו מידית לכתובת הדואר האלקטרוני: <a href="mailto:info@meirim.org">info@meirim.org</a>, על מנת שנוכל לבחון את העניין ולטפל בו, מוקדם ככל שניתן.
                    </p>
                    <h4><b><u>תנאים אחרים</u></b></h4>
                    <p>
                        <u>1. שינוי תנאים</u>
                        <br/>
                        הפלטפורמה ופעילותנו עשויים להשתנות מעת לעת. לפיכך, מפעם לפעם יהיה צורך לשנות את מדיניות הפרטיות הזו. אנו שומרים על הזכות, על פי שיקול דעתנו, לעדכן או לשנות את מדיניות פרטיות זו, בכל עת (להלן: "השינויים"). אנו נודיע על שינויים למדיניות הפרטיות באמצעות הכותרת "עודכן לאחרונה" המופיעה בראש מדיניות פרטיות זו. באחריותך להיכנס לדף זה, מעת לעת, בכדי לברר האם בוצעו שינויים במדיניות הפרטיות, בייחוד לפני מתן מידע אישי. מועד העדכון האחרון של מדיניות הפרטיות מופיע בראש העמוד. המשך השימוש בשירותים מהווה הסכמה מתמשכת לכל שינוי במדיניות פרטיות, כפי שיתבצע מעת לעת. אם שינוי כלשהו במדיניות הפרטיות לא יהיה לרוחך, הסעד היחיד העומד לרשותך יהיה הפסקת השימוש בשירותים או הגלישה באתר.
                        <br/>
                        <u>2. דיוור ישיר</u>
                        <br/>
                        &nbsp;&nbsp;&nbsp;2.1. ייתכן ונהיה מעוניינים לשלוח אליך מדי פעם באמצעות מסרונים או דואר אלקטרוני, מידע בדבר שירותיה וכן מידע שיווקי ופרסומי.
                        <br/>
                        &nbsp;&nbsp;&nbsp;2.2. בעת יצירת חשבון משתמש, ייתכן ותישלח למשתמש הודעת דואר אלקטרוני ו/או מסרון ובה קוד אימות שנועד לצרכי זיהוי והרשמה. המשתמש מאשר למפעילת הפלטפורמה לשלוח הודעות כאמור ומתחייב לשמור על הקוד בסודיות ולצורך הרשמתו האישית בלבד. אין להעביר את הקוד לכל גורם אחר.
                        <br/>
                        &nbsp;&nbsp;&nbsp;2.3. ניתן להירשם במערכת לשירות התראות שיישלחו בדואר אלקטרוני על נושאים מסוימים בהתאם להגדרות שבמערכת. משתמש שנרשם לשירות כאמור רשאי להסיר את בקשתו בכל עת, באמצעות קישור "הסר" שבהודעה או בהגדרותיו במערכת.
                        <br/>
                        &nbsp;&nbsp;&nbsp;2.4. השימוש בשירותים או בחלקם, לרבות מסירת מספר הטלפון או כתובת הדואר האלקטרוני שלך, מהווים הסכמה מפורשת למשלוח הודעות כאמור. עם זאת, בכל עת תוכל לבטל את הסכמתך על-ידי הודעה מתאימה לכתובת הדואר האלקטרוני: <a href="mailto:info@meirim.org">info@meirim.org</a>.
                        <br/>
                        &nbsp;&nbsp;&nbsp;2.5. על אף האמור, אנו נהיה רשאים לשלוח הודעות ישירות אשר למיטב אמונתנו הנן קריטיות לצורך שימושך בפלטפורמה, גם אחרי בחירתך להסיר את הסכמתך.
                        <br/>
                        <u>3. עוגיות (Cookies)</u>
                        <br/>
                        &nbsp;&nbsp;&nbsp;3.1. אנו עשויים להשתמש בקבצי Cookie לשם הבטחת שימוש מתמשך ונאות של הפלטפורמה או אתר האינטרנט שלנו, לרבות איסוף סטטיסטיקות, אימות, עדכונים וכו'.
                        <br/>
                        &nbsp;&nbsp;&nbsp;3.2. דפדפני אינטרנט מודרניים מאפשרים לך להימנע משימוש בקבצי עוגיות. אם אינך יודע איך לעשות זאת, בדוק את תפריט ה"עזרה" של הדפדפן שלך.
                        <br/>
                        <u>4. פרסומות של צדדים שלישיים</u>
                        <br/>
                        &nbsp;&nbsp;&nbsp;4.1. כחלק מהשירותים, פרסומות של צדדים שלישיים עשויות להיות מוצגות באתר או בפלטפורמה. פרסומות כאמור מסופקות בחסות שירות חיצוני – המתאים את הפרסומות לתוכן הדף ולהרגלי הגולש.
                        <br/>
                        &nbsp;&nbsp;&nbsp;4.2. יתכן ושירות חיצוני זה נעזר בקבצי Cookie המוצבים במכשירך בכדי לנהל ולהתאים את מערך הפרסומות. מובהר כי שימוש זה נעשה בכפוף למדיניות הפרטיות של השירות החיצוני ואינו כפוף למדיניות פרטיות זו.
                        <br/>
                        <u>5. זכות למחיקה, תיקון והעברה</u>
                        <br/>
                        עומדת לרשותך הזכות לדרוש כי המידע האישי שלך יוסר, יתוקן או יועבר אליך, ולפנות בבקשות נוספות בקשר עם המידע האישי שלך, באמצעות משלוח דואר אלקטרוני מפורט ל: <a href="mailto:info@meirim.org">info@meirim.org</a>.
                        <br/>
                        <u>6. ברירת דין וסמכות שיפוט</u>
                        <br/>
                        הדין שיחול על מדיניות הפרטיות יהיה הדין הישראלי. סמכות השיפוט הייחודית והבלעדית בכל הקשור לנושאים ולעניינים הנובעים או הקשורים למדיניות הפרטיות, תהא אך ורק לבית המשפט המוסמך שמקום מושבו בתל-אביב, ישראל.
                    </p>
			</div>
		</Wrapper>
	);
}
