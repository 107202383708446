import { default as aep6nkPOCy0 } from './aep6nkPOCy0.png';
import { default as MCeb6ovzbww } from './MCeb6ovzbww.png';
import { default as qNNl5QGpetE} from './qNNl5QGpetE.png';
import {default as gqP68pSR2uM} from './gqP68pSR2uM.png';
import {default as UIUX} from './53c0RsHhUoo.png';
import { default as IdGED3kke9U } from './IdGED3kke9U.png';

const Videos = {
    '53c0RsHhUoo': UIUX, 
    gqP68pSR2uM,
    qNNl5QGpetE,
    aep6nkPOCy0,
    MCeb6ovzbww,
    IdGED3kke9U

}
export default Videos;
